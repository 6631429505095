<template>
  <div>
    <div class="pc_plan">
      <div class="banner-box">
        <div class="banner-boxs">
          <div class="banner-name">医院集团化、县域医共体建设规划</div>
          <div class="banner-bnt" @click="openPDF">下载资料</div>
        </div>
      </div>
      <div class="plan-project" v-scroll-reveal>
        <div class="content-box">
          <div class="plan-title">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>项目介绍</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="plan-introduce">
            深化医药卫生体制改革的重要举措内容，是落实分级诊疗、高质量医院发展的重要措施。改革没有现成的模式，在实际运行过程中存在很多问题，需要整体规划。统筹安排，更需要有解决问题的方法。
          </div>
          <img class="plan-img" src="../assets/img/plan2.png" alt="" />
        </div>
        <projectMod :projectData="projectData" @relationUs="relationUs"/>
      </div>
      <cycleMod :cycleData="cycleData" />
      <serviceAcc :bg="1" />
      <expertItemMod />
      <relation :open="open" @openFun="openFun" />
    </div>
    <mobilePlan
      :projectData="projectData"
      class="mobile_plan"
    />
  </div>
</template>

<script>
import expertItemMod from "../components/expertItemMod";
import serviceAcc from "../components/serviceAcc";
import projectMod from "../components/projectMod";
import cycleMod from "../components/cycleMod";
import relation from "../components/relation";
import mobilePlan from '@/mobileViews/plan.vue'
export default {
  components: {
    expertItemMod,
    serviceAcc,
    projectMod,
    cycleMod,
    relation,
    mobilePlan
  },
  data() {
    return {
      open:false,
      projectData: {
        title: "项目内容",
        introduce: "本项目为医院集团，医共体提供如下解决方案",
        isBnt: true,
        fontWeight: 400,
        list: [
          {
            img: require("../assets/img/plan_icon1.png"),
            name: "制定方案",
            introduce: "医院集团、医共体最佳组织机构方案;",
          },
          {
            img: require("../assets/img/plan_icon2.png"),
            name: "评价方案",
            introduce: "医院集团、医共体考核、评价方案;",
          },
          {
            img: require("../assets/img/plan_icon4.png"),
            name: "成本方案",
            introduce: "医院集团、医共体最佳成本控制方法;",
          },
          {
            img: require("../assets/img/plan_icon3.png"),
            name: "优化运营",
            introduce: "医院集团、医共体最佳运营方式;",
          },
          {
            img: require("../assets/img/plan_icon5.png"),
            name: "制定薪酬方案",
            introduce: "医院集团、医共体薪酬方案;",
          },
          {
            img: require("../assets/img/plan_icon6.png"),
            name: "布局方案",
            introduce: "医院集团、医共体各成员医院最佳功能布局;",
          },
          {
            img: require("../assets/img/plan_icon7.png"),
            name: "战略规划",
            introduce: "医院集团、医共体战略规划。",
          },
          {
            img: require("../assets/img/plan_icon9.png"),
            name: "制作综合方案",
            introduce: "医院集团、医共体资源、市场、人才共享解决方案;",
          },
          {
            img: require("../assets/img/plan_icon8.png"),
            name: "发展方案",
            introduce: "医院集团、医共体各成员医院差异化发展方案;",
          },
        ],
      },

      cycleData: {
        title: "项目完成周期",
        introduce: "",
        list: [
          "咨询",
          "签约",
          "启动医院集团化，县域医共同体建设规划项目",
          "多维度培训赋能",
          "结项",
        ],
        list2: [
          { cycle: "7-10个工作日", introduce: "现场调研" },
          { cycle: "20-30个工作日", introduce: "规划制定" },
          { cycle: "1-2次", introduce: "专家研讨" },
          { cycle: "5-10个工作日", introduce: "规划修改完善" },
          { cycle: "2-3个工作日", introduce: "规划发布，实施辅导" },
        ],
        list3: [
          "调研方式：座谈、走访、文件研读、资料收集;",
          "调研内容：区域医疗市场分析、医院服务能力分析、医院经营能力分析、病源流向分析、地方医改政策分析等。",
        ],
      },
    };
  },
  methods: {
    /** 打开弹窗-组件内通知打开 */
    relationUs(bo) {
      this.open = bo;
    },
    /** 提交后关闭 */
    openFun(bo) {
      this.open = bo;
    },
    openPDF() {
      window.open(
        "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/protalDown/%E6%98%93%E7%AD%96%E5%8C%BB%E7%AE%A1-%E5%8C%BB%E9%99%A2%E9%9B%86%E5%9B%A2%E5%8C%96%E3%80%81%E5%8E%BF%E5%9F%9F%E5%8C%BB%E5%85%B1%E4%BD%93%E5%BB%BA%E8%AE%BE%E8%A7%84%E5%88%92.pdf"
      );
    },
  },
};
</script>

<style lang="scss">
.banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/plan.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  .banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 89px;
    }

    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: #4273ff;
      }
    }
  }
}
.content-box {
  width: 1200px;
  margin: 0 auto;
}
.plan-title {
  margin-top: 76px;
}
// .plan-title2 {
//   padding-top: 95px;
// }
.plan-introduce {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}
.plan-img {
  display: inline-block;
  width: 1200px;
  height: 342px;
}
.plan-project {
  background-image: url("../assets/img/plan_project_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 67px;
}

.mobile_plan {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_plan {
    display: block;
    background-color: #fff;
  }
  .pc_plan {
    display: none;
  }
}
</style>
