<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">医院集团化、县域医共体建设规划</div>
      <!-- <div class="m_banner_i"></div>
      <div class="m_banner_text2">五定一做一分析，精准战略定位和发展方向</div> -->
    </div>
    <div class="plan_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目框架</span>
        <span class="index_title_after"></span>
      </div>
      <img class="plan_m_img" src="../assets/img/plan2.png" alt="">
      <div class="plan_m_text">
        深化医药卫生体制改革的重要举措内容，是落实分级诊疗、
        高质量医院发展的重要措施。改革没有现成的模式，在实
        际运行过程中存在很多问题，需要整体规划。统筹安排，
        更需要有解决问题的方法。
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">{{ projectData.title }}</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">{{ projectData.introduce }}</div>
      <div>
        <div class="modern_m_kernel_list">
          <div class="modern_m_kernel_item" v-for="(item, index) in projectData.list" :key="index">
            <img class="modern_m_kernel_img" :src="item.img" alt="">
            <div class="modern_m_kernel_name">{{ item.name }}</div>
            <div class="modern_m_kernel_introduce">{{ item.introduce }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modern_m_period">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目完成周期</span>
        <span class="index_title_after"></span>
      </div>
      <div class="modern_m_period_list">
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">01</div>
          <div class="modern_m_period_title2">7-10个工作日</div>
          <div class="modern_m_period_title">现场调研</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">02</div>
          <div class="modern_m_period_title2">20-30个工作日</div>
          <div class="modern_m_period_title">规划制定</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">03</div>
          <div class="modern_m_period_title2">1-2次</div>
          <div class="modern_m_period_title">专家研讨</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">04</div>
          <div class="modern_m_period_title2">5-10个工作日</div>
          <div class="modern_m_period_title">规划修改完善</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">05</div>
          <div class="modern_m_period_title2">2-3个工作日</div>
          <div class="modern_m_period_title">规划发布，<br />实施辅导</div>
        </div>
        <img class="modern_m_period_img" src="../assets/img/period_m_img.png" alt="">
      </div>
      <div class="modern_m_period_ii">启动医院集团化，县域医共同体建设规划项目</div>
    </div>
    <div class="serve_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">服务案例</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
      <div class="serve_m_type">
        <div
          :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
          v-for="(item, index) in hisTypeList"
          :key="index" 
          @click="changeTab(item.type)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="serve_m_hospital">
        <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
          <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
          <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
        </div>
      </div>
      <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
    </div>
  </div>
</template>

<script>
  import { getHospitalList } from "@/api/index";
  export default {
    props: ['projectData'],
    data() {
      return {
        is_big: false,
        isType: null,
        hisShow: false,
        hisTypeList: [
          { type: null, value: "全部" },
          { type: "综合医院", value: "综合医院" },
          { type: "中医医院", value: "中医医院" },
          { type: "妇幼保健院", value: "妇幼保健院" },
          { type: "其他类型", value: "其他类型" },
        ],
        hisList2: []
      }
    },
    created() {
      this.changeTab(null)
    },
    methods: {
      changeTab(i) {
        this.isType = i;
        let data = {
          // hospitalProvince: this.HisTilter,
          hospitalType: i,
        };
        getHospitalList(data).then((res) => {
          if (res.code == 200) {
            this.hisList2 = res.rows.slice(0, 10);
            this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
            this.hisShow = false
          }
        });
      },
      stretch(){
        this.hisShow = !this.hisShow
        if(this.hisShow == false){
          this.hisList2 = this.hisListCopy.slice(0, 10)
        }else{
          this.hisList2 = this.hisListCopy
        }
      },
      getDetails(hospitalId) {
        this.$router.push({
          path: "/serve",
          query: { activeName:2, hospitalId: hospitalId },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg12.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 42px;
      color: #fff;
      text-align: center;
      padding-top: 200px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 26px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 40px auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .plan_m_box {
    padding: 37px;
    .plan_m_img {
      width: 100%;
    }
    .plan_m_text {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      padding-top: 30px;
    }
    .modern_m_kernel_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 45px 0;
        .modern_m_kernel_item {
          width: 328px;
          text-align: center;
          background: #FFFFFF;
          box-shadow: 0px 3px 10px 1px rgba(146,146,146,0.16);
          border: 1px solid #E1E1E1;
          padding: 29px 0;
          margin-bottom: 30px;
          .modern_m_kernel_img {
            width: 62px;
          }
          .modern_m_kernel_name {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            padding: 15px 10px;
          }
          .modern_m_kernel_introduce {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            padding: 0 10px;
          }
          &:nth-child(9) {
            width: 100%;
          }
        }
      }
    
  }
  .modern_m_period {
    background-color: #F5F6F8;
    padding: 45px 37px 0 37px;
    background-image: url("../assets/img/cycle_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .modern_m_period_list {
      position: relative;
      height: 500px;
      .modern_m_period_img {
        width: 336px;
        height: 259px;
        position: absolute;
        top: 200px;
        left: 170px;
      }
      .modern_m_period_item {
        position: absolute;
        z-index: 2;
        .modern_m_period_month {
          font-size: 26px;
          font-weight: bold;
          color: #1E61FF;
          margin-bottom: 16px;
          &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #1E61FF;
            border-radius: 100%;
            margin-right: 8px;
            margin-bottom: 2px;
          }
        }
        .modern_m_period_title {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
        }
        .modern_m_period_title2 {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }
        &:nth-child(1) {
          top: 112px;
          left: 20px;
        }
        &:nth-child(2) {
          top: 60px;
          left: 270px;
        }
        &:nth-child(3) {
          top: 112px;
          left: 500px;
        }
        &:nth-child(4) {
          top: 300px;
          left: 10px;
        }
        &:nth-child(5) {
          top: 300px;
          right: 10px;
        }
      }
    }
    .modern_m_period_ii {
      width: fit-content;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      padding-bottom: 50px;
      margin: 0 auto;
      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #1E61FF;
        border-radius: 100%;
        margin-right: 12px;
        margin-top: -12px;
      }
    }
  }
  .serve_m_box {
    background-color: #fff;
    padding: 50px 37px;
    .serve_m_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 60px;
      .serve_m_type_item {
        width: fit-content;
        padding: 21px 30px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #BFBFBF;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .serve_m_type_isItem {
        color: #fff;
        border: 1px solid #2D43F2;
        background: #2D43F2;
      }
    }
  }
  .serve_m_hospital {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 41px;
    .serve_m_hospital_item {
      width: 48%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
      border-radius: 4px;
      border: 2px solid #E6E6E6;
      margin-bottom: 28px;
      padding: 21px;
      .serve_m_hospital_img {
        width: 138px;
        height: 138px;
      }
      .serve_m_hospital_name {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding-top: 20px;
      }
    }
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
</style>